import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import { useLocation } from "react-router-dom";
import { GetVisit, GetBase64PdfReceipt, Check_set } from "~slices/Check";
import {
    TextField,
    InputAdornment,
    Typography,
    Button,
    Stack,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Grid,
    Modal,
    Checkbox,
    Dialog,
    FormControlLabel,
} from "@mui/material";

import "~css/receipt.css";
import { FormatTime, ParseTime, FormatOpeningHours } from "~/components/functions/DateTimeFunctions";

const Receipt = () => {
    const dispatch = useDispatch();
    const CheckState = useSelector((state) => state.Check);
    const visit_data = CheckState.visit_data;
    const informationState = useSelector((state) => state.Information);
    const config = informationState.config;

    // const CheckState = useSelector((state) => state.Check);
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const visit_id = query.get("visit_id");

    const params = { visit_id: visit_id };

    // 合計金額
    const [total, setTotal] = useState(Number(visit_data.amount));
    const [receipt_proviso, setReceiptProviso] = useState(config.receipt_proviso);
    const [client_name, setClient_name] = useState(visit_data.client_name);

    const business_day = visit_data?.business_day ? visit_data?.business_day : null;

    const [date, setDate] = useState(FormatTime(new Date(business_day), "jp_date"));

    React.useEffect(() => {
        setDate(FormatTime(new Date(business_day), "jp_date"));
    }, [business_day]);

    React.useEffect(() => {
        setTotal(Number(visit_data.amount));
    }, [visit_data.amount]);

    React.useEffect(() => {
        setClient_name(visit_data.client_name);
    }, [visit_data.client_name]);

    //========================8%対応=================================================================
    const total_tax = CheckState.total_tax;
    const tax08 = CheckState.tax_08;
    const tax10 = CheckState.tax_10;
    const tax_excluded = CheckState.tax_excluded;

    React.useEffect(() => {
        dispatch(GetVisit(params));

        dispatch(Check_set(["tax_08", "0"]));
        dispatch(Check_set(["tax_10", "0"]));
    }, []);
    //=====================================================================================

    const HandleDownload = () => {
        const link = document.createElement("a");
        link.download = `download.pdf`;
        const url = "/DownloadReceipt";

        const logo = config.accounting_detail_logo.startsWith("/")
            ? config.accounting_detail_logo.slice(1)
            : config.accounting_detail_logo;
        const params = {
            logo: logo,
            total: total,
            total_tax: total_tax,
            tax08: tax08 ? tax08 : 0,
            tax10: tax10 ? tax10 : 0,
            receipt_proviso: receipt_proviso,
            client_name: client_name,
            // consumption_tax_excluded: Math.round(total - Number(total_tax)).toLocaleString(),
            consumption_tax_excluded: Math.round(Number(tax_excluded)).toLocaleString(),
            consumption_tax: Math.round(Number(total) - Number(total / 1.1)).toLocaleString(),
            date: date,
            receipt_address: config.receipt_address,
            receipt_telephone: config.receipt_telephone,
            receipt_invoice: config.receipt_invoice,
        };
        const encodedurl = url + "?" + new URLSearchParams(params);

        link.href = encodedurl;
        link.click();
    };

    const HandlePrint = () => {
        let thisPage = window.location.href;

        const logo = config.accounting_detail_logo.startsWith("/")
            ? config.accounting_detail_logo.slice(1)
            : config.accounting_detail_logo;
        const params = {
            logo: logo,
            total: total,
            total_tax: total_tax,
            tax08: tax08 ? tax08 : 0,
            tax10: tax10 ? tax10 : 0,
            receipt_proviso: receipt_proviso,
            client_name: client_name,
            // consumption_tax_excluded: Math.round(total - Number(total_tax)).toLocaleString(),
            consumption_tax_excluded: Math.round(Number(tax_excluded)).toLocaleString(),
            consumption_tax: Math.round(Number(total) - Number(total / 1.1)).toLocaleString(),
            date: FormatTime(new Date(), "jp_date"),
            receipt_address: config.receipt_address,
            receipt_telephone: config.receipt_telephone,
            receipt_invoice: config.receipt_invoice,
        };
        dispatch(GetBase64PdfReceipt(params)).then((data) => {
            const pdfData = data.payload.Base64Pdf;

            let url =
                "siiprintagent://1.0/print?" +
                "CallbackSuccess=" +
                encodeURIComponent(thisPage) +
                "&" + // URL for printing success
                "CallbackFail=" +
                encodeURIComponent(thisPage) +
                "&" + // URL for printing fail
                "Format=" +
                "pdf" +
                "&" + // Format ('pdf' fixed)
                "Data=" +
                encodeURIComponent(pdfData) +
                "&" + // PDF data encoded with Base64 Encoding
                "SelectOnError=" +
                "yes" +
                "&" + // Printer selection dialog displayed when a failure occurs
                "CutType=" +
                "full" +
                "&" + // Cut type
                "CutFeed=" +
                "yes" +
                "&" + // Cut feed
                "FitToWidth=" +
                "yes" +
                "&" + // Cut feed
                "Rotation=" +
                "90" +
                "&" + // Fitting printing width to PaperWidth
                "PaperWidth=" +
                "100"; // Paper width
            location.href = url;
        });
    };

    return (
        <React.Fragment>
            <div className="hideInPrint" style={{ position: "absolute" }}>
                <Button variant="contained" onClick={() => history.back()}>
                    戻る
                </Button>
                <Button variant="contained" onClick={HandleDownload} sx={{ right: "0", position: "fixed" }}>
                    PDFダウンロード
                </Button>

                <Button variant="contained" onClick={HandlePrint} sx={{ right: "0", position: "fixed", top: "2.5rem" }}>
                    領収書印刷
                </Button>
            </div>

            <Container
                sx={{
                    pt: 2,
                    pb: 2,
                    backgroundSize: "100% 100%",
                    textAlign: "center",
                    // minHeight: "80vh",
                    display: "grid",
                    display: "inline-table",
                }}
            >
                <Grid container>
                    <Grid item xs={3}>
                        <Typography variant="h4" gutterBottom align="right">
                            領収書
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="h4" gutterBottom align="center">
                            {/* {visit_data.client_name} */}
                            <input
                                style={{ border: "none", width: "50%" }}
                                type="text"
                                value={client_name}
                                onChange={(e) => setClient_name(e.target.value)}
                                // onBlur={() => setTotal(Number(total).toLocaleString())}
                            />
                            様
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: "-webkit-center", fontSize: "2rem", border: "double" }}>
                        {/* <Typography
                            variant="h4"
                            gutterBottom
                            align="right"
                            sx={{ borderBottom: "solid 1px", width: "max-content" }}
                        > */}
                        金額 ¥{/* {Number(visit_data.amount).toLocaleString()} */}
                        <input
                            style={{ border: "none", width: "12rem" }}
                            type="text"
                            // value={Number(String(total).replaceAll(",", "")).toLocaleString()}
                            // onChange={(e) => setTotal(String(e.target.value).replaceAll(",", ""))}
                            value={total}
                            onChange={(e) => setTotal(e.target.value)}
                            // onBlur={() => setTotal(Number(total).toLocaleString())}
                        />
                        {/* </Typography> */}
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle1" gutterBottom align="left">
                            {/* {FormatTime(new Date(), "jp_date")}
                             */}

                            <input
                                style={{ border: "none", width: "100%" }}
                                type="text"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="subtitle1" gutterBottom align="right" sx={{ borderBottom: "solid 1px" }}>
                            <input
                                style={{ border: "none", width: "100%" }}
                                type="text"
                                value={receipt_proviso}
                                onChange={(e) => setReceiptProviso(e.target.value)}
                            />
                        </Typography>{" "}
                    </Grid>

                    {/* <Grid item xs={6}>
                        <Typography variant="subtitle1" gutterBottom align="right">
                            {FormatTime(new Date(), "jp_date")}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom align="right" sx={{ borderBottom: "solid 1px" }}>
                            <input
                                style={{ border: "none", width: "100%" }}
                                type="text"
                                value={receipt_proviso}
                                onChange={(e) => setReceiptProviso(e.target.value)}
                            />
                        </Typography>
                    </Grid> */}
                </Grid>

                <Grid container>
                    <Grid item xs={4}>
                        <Grid container direction="column" justifyContent="center" alignItems="flex-start">
                            <Typography variant="subtitle1" gutterBottom align="left">
                                内訳
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                gutterBottom
                                align="left"
                                sx={{ borderBottom: "solid 1px", width: "60%" }}
                            >
                                {/* 税抜き金額 ¥{Math.round(total - Number(total_tax)).toLocaleString()} */}
                                税抜き金額 ¥{tax_excluded}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                gutterBottom
                                align="left"
                                sx={{ borderBottom: "solid 1px", width: "60%" }}
                            >
                                消費税8% ¥{/* {Number(visit_data.amount).toLocaleString()} */}
                                {tax08 ? tax08 : 0}
                                {/* {Math.round(Number(total) - Number(total / 1.1)).toLocaleString()} */}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                gutterBottom
                                align="left"
                                sx={{ borderBottom: "solid 1px", width: "60%" }}
                            >
                                消費税10% ¥{/* {Number(visit_data.amount).toLocaleString()} */}
                                {/* {Math.round(Number(total) - Number(total / 1.1)).toLocaleString()} */}
                                {tax10 ? tax10 : 0}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={8} sx={{ display: "flex" }}>
                        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-end">
                            <Grid item>
                                {/* <Typography variant="subtitle1" gutterBottom align="right">
                                住所
                            </Typography> */}
                                {/* <Typography variant="subtitle1" gutterBottom align="right">
                            〒542-0083
                        </Typography> */}
                                <Typography variant="subtitle1" gutterBottom align="right" sx={{ fontSize: "0.8rem" }}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: config.receipt_address.replace(/\n/g, "<br>"),
                                        }}
                                    ></div>
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                    align="right"
                                    sx={{ borderBottom: "solid 1px", fontSize: "0.8rem" }}
                                >
                                    電話番号 {config.receipt_telephone}
                                </Typography>
                            </Grid>
                            <Typography variant="subtitle1" gutterBottom align="left" sx={{ fontSize: "0.8rem" }}>
                                インボイス登録番号　{config.receipt_invoice}
                            </Typography>
                        </Grid>
                        <Grid item sx={{ display: "contents" }}>
                            <img
                                src={config.accounting_detail_logo}
                                style={{
                                    height: "3.5rem",
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                }}
                            />{" "}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                // sx={{ marginTop: "auto", display: "table-footer-group" }}
                >
                    {/* <img
                        src={config.accounting_detail_logo}
                        style={{ width: "30%" }}
                        // style={{ width: "50%", maxHeight: "300px", marginBottom: "1.2rem" }}
                        // style={{ objectFit: "cover", width: "480px", height: "270px" }}
                    /> */}
                </Grid>

                <Box sx={{textAlign:"left"}}>
                {/* <Button variant="contained" onClick={() => history.back()}>
                    調整金額を8%に含める
                </Button> */}
                </Box>
            </Container>
        </React.Fragment>
    );
};


export default Receipt;
